/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import React, { useState, useEffect, useRef } from 'react'
import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Fade'

import axios from 'axios'

import { globalCss, variables, colors } from '../styles/global'
import Layout from '../components/layout'
import SEO from '../components/seo'

const ContactPage = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const [showPopUp, setShowPopUp] = useState(false)
  const formRef = useRef()
  const handleServerResponse = (ok: any, msg: any, form: any) => {
    setServerState({
      submitting: false,
      // @ts-ignore
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
      setShowPopUp(true)
    }
  }
  const handleOnSubmit = (e: any) => {
    e.preventDefault()

    if (typeof document !== 'undefined') {
      if (document.getElementById('Anders').value !== '') {
        return
      } else {
        const form = e.target
        // @ts-ignore
        setServerState({ submitting: true })
        axios({
          method: 'post',
          url: 'https://getform.io/f/323761d3-abab-4e86-88b5-653262a99dcd',
          data: new FormData(form),
        })
          .then((r) => {
            handleServerResponse(true, 'Thanks!', form)
          })
          .catch((r) => {
            handleServerResponse(false, r.response.data.error, form)
          })
      }
    }
  }

  return (
    <Layout hideMenu={false}>
      <SEO
        title="Kijksessie"
        description="Kom vrijblijvend eens kennismaken!"
      />
      <div
        onClick={() => setShowPopUp(!showPopUp)}
        className={showPopUp ? 'show' : 'hide'}
        css={css`
          pointer-events: ${showPopUp ? 'normal' : 'none'};
          height: 100vh;
          width: 100vw;
          position: fixed;
          transition: opacity 0.5s;
          z-index: 6;
        `}
      >
        <div
          css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: black;
            opacity: 0.4;
          `}
        ></div>
        <div
          css={css`
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 35%;
            padding: 0 40px 20px;
            min-width: 280px;
            background-color: ${colors.black};
            color: ${colors.white};
            border: 1px solid ${colors.pink};
            border-radius: 10px;
            text-align: center;
          `}
        >
          <h4 css={css``}>Wat leuk dat je ons benadert!</h4>
          <p>We nemen binnen enkele werkdagen contact met je op.</p>
          <p
            css={css`
              font-size: 0.7rem;
            `}
          >
            Klik om verder te gaan
          </p>
        </div>
      </div>

      <div
        css={css`
          width: auto;
          max-width: 800px;
          margin: auto;
          background-color: ${colors.black};
          color: ${colors.white};
          padding: 30px ${variables.HPaddingDesktop}px;
          ${variables.mobile} {
            padding: 30px ${variables.HPaddingMobile}px;
          }
        `}
      >
        <h2
          css={css`
            margin-top: 60px;
          `}
        >
          Kom eens langs!
        </h2>
        <p
          css={css`
            width: 80%;
            ${variables.mobile} {
              width: auto;
            }
          `}
        >
          Benieuwd geworden hoe deze flamingo's klinken? Vul het formulier
          hieronder in voor een vrijblijvende prijsopgave! We nemen dan ook
          contact met je op om langs te komen op een van onze maandelijkse
          kijksessies, waar je getrakteerd wordt op een miniconcertje en rustig
          kunt kennismaken met de band.
        </p>
        <form
          css={css`
            margin-bottom: 100px;
          `}
          id="kijksessieForm"
          onSubmit={handleOnSubmit}
        >
          <div
            css={css`
              display: flex;
              width: 100%;
              ${variables.mobile} {
                flex-direction: column;
              }
            `}
          >
            <div
              css={css`
                width: 50%;
                ${variables.mobile} {
                  width: 100%;
                }
              `}
            >
              <h4>Over jou:</h4>
              <div className="formDiv">
                <label htmlFor="Naam" className="inputLabel">
                  Naam:
                </label>
                <input
                  className="inputKlein"
                  type="text"
                  id="Naam"
                  name="Naam"
                  required
                />
              </div>
              <div className="formDiv">
                <label htmlFor="Email" className="inputLabel">
                  Email:
                </label>
                <input
                  className="inputKlein"
                  type="email"
                  id="Email"
                  name="Email"
                  required
                />
              </div>
              <div className="formDiv">
                <label htmlFor="Telefoon" className="inputLabel">
                  Telefoon:
                </label>
                <input
                  className="inputKlein"
                  type="tel"
                  id="Telefoon"
                  name="Telefoon"
                  required
                />
              </div>
              <div className="formDiv">
                <label htmlFor="Adres" className="inputLabel">
                  Adres:
                </label>
                <input
                  className="inputKlein"
                  type="text"
                  id="Adres"
                  name="Adres"
                  required
                />
              </div>
              <div className="formDiv">
                <label htmlFor="Woonplaats" className="inputLabel">
                  Woonplaats:
                </label>
                <input
                  className="inputKlein"
                  type="text"
                  id="Woonplaats"
                  name="Woonplaats"
                  required
                />
              </div>
              <div className="formDiv">
                <label htmlFor="Hoe Kent U Ons" className="inputLabel">
                  Hoe bent u bij ons terecht gekomen?
                </label>
                <select required id="Hoe Kent U Ons" name="Hoe Kent U Ons">
                  <option hidden disabled selected value=""></option>
                  <option value="Google">Google</option>
                  <option value="Socials">Facebook/Instagram</option>
                  <option value="Tentfeesten magazine">
                    Tentfeesten magazine
                  </option>
                  <option value="Live Gezien">
                    Ik heb jullie ergens anders zien optreden
                  </option>
                  <option value="Van Horen Zeggen">Van horen zeggen</option>
                  <option value="Anders">Anders</option>
                </select>
              </div>
            </div>
            <div
              css={css`
                width: 50%;
                ${variables.mobile} {
                  width: 100%;
                }
              `}
            >
              <h4>Over jouw feest:</h4>
              <div className="formDiv">
                <label htmlFor="Soort Feest" className="inputLabel">
                  Soort feest (bruiloft, bedrijfsfeest, etc...):
                </label>
                <input
                  className="inputKlein"
                  type="text"
                  id="Soort Feest"
                  name="Soort Feest"
                  required
                />
              </div>
              <div className="formDiv">
                <label htmlFor="Datum" className="inputLabel">
                  Datum:
                </label>
                <input
                  className="inputKlein"
                  type="text"
                  id="Datum"
                  name="Datum"
                  required
                />
              </div>
              <div className="formDiv">
                <label htmlFor="Locatie" className="inputLabel">
                  Plaats van het feest:
                </label>
                <input
                  className="inputKlein"
                  type="text"
                  id="Locatie"
                  name="Locatie"
                  required
                />
              </div>
              <div className="formDiv">
                <label htmlFor="Aantal Gasten" className="inputLabel">
                  Verwacht aantal gasten:
                </label>
                <input
                  className="inputKlein"
                  type="number"
                  id="Aantal Gasten"
                  name="Aantal Gasten"
                  required
                />
              </div>
            </div>
          </div>
          <div
            css={css`
              margin-top: 2rem;
            `}
          >
            <label htmlFor="Overig" className="inputLabel">
              Uw vraag / opmerkingen:
            </label>
            <textarea id="Overig" name="Overig" rows={5} wrap="physical" />
          </div>

          <div
            css={css`
              margin-top: 2rem;
              transform: scale(0.000001);
              margin-bottom: -12rem;
            `}
          >
            <label htmlFor="Anders" className="inputLabel">
              Uw vraag / opmerkingen:
            </label>
            <textarea id="Anders" name="Anders" rows={5} wrap="physical" />
          </div>

          <div className="formDiv">
            <button
              type="submit"
              id="verstuur"
              className="submitButton"
              css={css`
                &:hover {
                  cursor: pointer;
                }
              `}
            >
              Verstuur
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default ContactPage
